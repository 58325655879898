window.initInfiniteScroll = ->
  container = document.querySelector(".js-inf:not(.js-inf-initialized)")
  if container && !container.classList.contains('js-inf-initialized')
    container.classList.add('js-inf-initialized')
    window.infScroll = new InfiniteScroll(container,
      path: ".js-inf-next"
      append: ".js-inf-append"
      status: ".js-inf-status"
      history: false
      fetchOptions: ->
        {
          headers: {
            'X-Except-Ads': container.dataset.except
          }
        })

    infScroll.on "append", (body, path, response) =>
      container.dataset.except = body.querySelector('.js-inf').dataset.except

      # console.log('append:load')
      adsList = response[0].querySelectorAll('.ctad')
      initImpression(adsList)

    infScroll.on "request", (path, fetchPromise) =>
      trackEvent('Infinite Scroll', path: path)

    initImpression(container.querySelectorAll('.ctad'))

document.addEventListener 'turbo:load', ->
  current_url = window.lcation.pathname
  if (current_url.startsWith('/blog'))
    promo = document.querySelectorAll('.blog table .ctad')
    if promo.length > 0
      initImpression(promo)

document.addEventListener 'turbo:load', initInfiniteScroll

document.addEventListener 'turbo:before-cache', ->
  loader = document.querySelector('.first-page-loader')
  if loader
    loader.style.display = 'block';

window.addEventListener 'popstate', (event) ->
  current_url = window.lcation.pathname

  infUrls = ['/search', '/blog', '/best-onlyfans', '/en/onlyfans/', '/new-onlyfans', '/free-onlyfans', '/onlyfans-trial-links', '/en/search-onlyfans/location', '/en/cities', '/en/states']

  if current_url == '/' || infUrls.some((url) -> current_url.startsWith(url)) || current_url.endsWith('-onlyfans-creators') || document.querySelector('.inf-spinner')
    # console.log('Turbo visit')
    Turbo.visit(location.href)

document.addEventListener 'turbo:before-cache', ->
  if window.infScroll
    infScroll.destroy()
    delete window.infScroll

document.addEventListener 'creators:loaded', () =>
  console.log('creators:load')
  initInfiniteScroll()
  initLocationFinder()
  initFavorite()
  initImpression()
