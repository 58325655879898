var scrollAmount, timer;

timer = void 0;

scrollAmount = 0;

window.addEventListener('scroll', function(e) {
  clearTimeout(timer);
  timer = setTimeout((function() {
    scrollAmount++;
  }), 500);
});

let callback = function(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      let elem = entry.target;
      let elemData = elem.querySelector('.js-creator-link').dataset

      if (elemData.varsImprsn) {
        let adList = Array.from(document.querySelectorAll('.ctad'));

        // console.log(elemData.varsUsername, adList.indexOf(elem))

        $.ajax({
          url: '/api/events',
          type: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            url: elemData.varsImprsn,
            position: adList.indexOf(elem),
            scroll: scrollAmount
          },
          success: (response) => {
            observer.unobserve(entry.target);
          }
        });
      }
    }
  });
};

let options = {
  root: document,
  rootMargin: '1000px 0px 500px 0px',
  threshold: 0
}

const observer = new IntersectionObserver(callback, options);

window.initImpression = function(entries = []) {
  if (entries.length === 0) {
    var entries = document.querySelectorAll('.ctad:not([observed="true"])');
  }
  // console.log('- init impression', entries.length, 'ads')
  entries.forEach(element => {
    if (!element.hasAttribute('observed') || element.getAttribute('observed') !== 'true') {
      observer.observe(element);
      element.setAttribute('observed', 'true');
    }
  });
};

