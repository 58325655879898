document.addEventListener 'turbo:load', ->
  $promoPopups = $('.js-promo-popups')
  totalPopups = $promoPopups.find('.js-promo-popup').length
  currentIndex = -1

  if totalPopups > 0
    setTimeout =>
      runPromoTick()
    , 4000

    runPromoTick = ->
      $currentlyShownPromo = findPromoPopupByIndex(currentIndex)
      if $currentlyShownPromo[0]?
        hidePromoPopup($currentlyShownPromo)

      currentIndex = currentIndex + 1
      currentIndex = 0 if currentIndex > totalPopups

      setTimeout =>
        $nextPromo = findPromoPopupByIndex(currentIndex)
        showPromoPopup($nextPromo)

        setTimeout =>
          runPromoTick()
        , 8000
      , 4000

findPromoPopupByIndex = (index) ->
  $('.js-promo-popups').find('.js-promo-popup').eq(index)

showPromoPopup = ($element) ->
  $element.addClass('active animate__animated animate__fadeInUp')

hidePromoPopup = ($element) ->
  $element.removeClass('animate__fadeInUp')
  $element.addClass('animate__fadeOutDown')

  setTimeout(=>
    $element.removeClass('active animate__animated animate__fadeOutDown')
  , 1000)
