import Cookies from 'js-cookie'

document.addEventListener 'turbo:load', =>
  $('.js-help-ukraine').on 'click', =>
    Cookies.set 'helpUkraineClicked', 'yes'
    $('.js-help-ukraine').remove()

  $('.js-help-ukraine-close').on 'click', (event) =>
    event.preventDefault()
    Cookies.set 'helpUkraineClicked', 'yes'
    $('.js-help-ukraine').remove()
