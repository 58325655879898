document.addEventListener 'turbo:load', ->
  $('.js-smooth-scroll').on 'click', (e) ->
    e.preventDefault()
    targetHref = $(@).attr('href')
    $target = if $(targetHref)[0] then $(targetHref) else $("a[name='#{targetHref.slice(1)}']")

    if !$target[0]
      console.log "Can't find element with #{targetHref} identifier"
      return

    $([document.documentElement, document.body]).animate(
      scrollTop: $target.offset().top - 150
    , 1000)
