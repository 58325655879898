import {mergeUrlParams} from "../utilities/mergeUrlParams"
import Cookies from 'js-cookie'

document.addEventListener 'turbo:load', ->
  initLocationFinder()

window.initLocationFinder = () =>
  window.showLocationLoader = -> $('#location-loader').show()
  window.hideLocationLoader = -> $('#location-loader').hide()
  window.cancelLocationFinder = ->
    window.lcationFinderPromiseReject.reject("Cancelled")
    hideLocationLoader()

  window.findCurrentLocation = (showLoading = true) ->
    if showLoading
      showLocationLoader()

    return new Promise (resolve, reject) =>
      window.lcationFinderPromiseResolve = resolve
      window.lcationFinderPromiseReject = reject

      unless !!navigator.geolocation
        hideLocationLoader()
        return reject("navigator.geolocation is undefined")

      successCallback = (position) =>
        hideLocationLoader()
        Cookies.set('last_location', position.coords.latitude + '%2C' + position.coords.longitude)
        resolve(position)

      errorCallback = (error) =>
        hideLocationLoader()
        console.error error

      navigator.geolocation.getCurrentPosition(successCallback, errorCallback)

  $('.js-find-nearby').on 'click', (e) =>
    $target = $(e.currentTarget)
    disableWithHTML = $target.data('location-disable-with')
    originalHTML = $target.html()
    originalText = $target.text()
    $target.attr('disabled', true)
    if !!disableWithHTML
      $target.html(disableWithHTML)

    e.preventDefault()

    if $target.closest('.dropdown')[0]
      e.stopPropagation() # Prevent dropdown from closing

      if $target.hasClass('active')
        # Remove ?map filter
        params = new URLSearchParams(location.search)

        if params.has('map')
          params.delete('map')
          finalUrl = location.pathname + '?' + params.toString()
        else
          finalUrl = location.pathname + location.search
        Turbo.visit(finalUrl)
        return

    trackEvent 'Location Finder', text: originalText, path: location.pathname

    findCurrentLocation().then((position) =>
      if window.mergeLocationFinderURL
        baseUrl = location.pathname + location.search
        finalUrl = mergeUrlParams(baseUrl, 'map', "#{position.coords.latitude}%2C#{position.coords.longitude}")
        Turbo.visit(finalUrl)
      else
        Turbo.visit("/search?map=#{position.coords.latitude}%2C#{position.coords.longitude}&nearby=true")
    ).catch((error) =>
      console.error "Error getting current location", error
    ).finally(() =>
      $target.attr('disabled', false)
      if disableWithHTML
        $target.html(originalHTML)
    )
