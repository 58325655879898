window.trackingClicks = false
window.initTrackClicks = ->
  if window.trackingClicks == true
    console.warn "Already tracking"
    return

  window.trackingClicks = true

  $(document).on 'click', '.js-creator-link', (e) ->
    $trigger = $(@)

    href = $trigger.attr('href')
    text = $trigger.text().toString().trim()
    text = null if text.length > 50
    username = $trigger.data('vars-username')
    promotionId = $trigger.data('vars-promotion-id')
    ctad = $trigger.data('vars-ctad')
    trialId = $trigger.data('vars-trial-id')
    clickTrack = $trigger.data('vars-clctrc')
    card = $trigger.closest('.ctad')[0]
    is_promoted = !!promotionId || !!clickTrack ? 'yes' : 'no'

    props =
      username: username
      href: href
      text: text
      target: $trigger[0].dataset.target or $trigger[0].tagName
      promotion_id: promotionId
      page: window.lcation.pathname
      trial_id: trialId

    if $trigger.data('skip-click-track') == undefined
      trackEvent('$profile:click', props)

    if $trigger[0].dataset.trackRedirect != undefined || href.toString().toLowerCase().includes('://onlyfans.com')
      trackEvent('$profile:redirect', props)
      gtag? && gtag('event', 'conversion', { send_to: 'G-2PWL9BPLY1/profile_redirect', promoted: is_promoted, username: username, page: window.lcation.pathname, ctad: !!clickTrack })

    if clickTrack
      # console.log("ad click: #{username}")
      submit_rtb(clickTrack, card)

    if trialId
      trial_props =
        username: username
        promotion_id: promotionId
        trial_id: trialId
        page: window.lcation.pathname

      trackEvent('$trial:click', trial_props)

    true

document.addEventListener 'turbo:load', initTrackClicks

submit_rtb = (str, element) ->
  adList = Array.from(document.querySelectorAll('.creator-card-v3.ctad'))

  $.ajax
    url: '/api/events'
    type: 'GET'
    headers: {
      'Content-Type': 'application/json'
    }
    data:
      url: str
      position: adList.indexOf(element)
      scroll: scrollAmount

timer = undefined
scrollAmount = 0
window.addEventListener 'scroll', (e) ->
  clearTimeout timer
  timer = setTimeout((->
    scrollAmount++
    return
  ), 500)
  return

# initImpression();
